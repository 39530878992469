import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './Dashboard.css';

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [message, setMessage] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [updateMessage, setUpdateMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const token = localStorage.getItem('token');
  const eventId = 'euro2024'; // Replace with actual event ID

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://assisai.com/matchesapi/api.php/group-points', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users', error);
      }
    };

    const fetchMatches = async () => {
      try {
        const response = await axios.get(`https://assisai.com/matchesapi/api.php/predictions/${eventId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        if (Array.isArray(data)) {
          setMatches(data);
        } else {
          setMatches([]);
        }
      } catch (error) {
        console.error('Failed to fetch matches', error);
        setMatches([]);
      }
    };

    fetchUsers();
    fetchMatches();
  }, [token, eventId]);

  const handlePredictionChange = (matchId, predictedscore1, predictedscore2) => {
    setMatches(matches.map(match => (
      match.matchId === matchId ? { ...match, predictedscore1, predictedscore2 } : match
    )));
  };

  const handleSavePrediction = async (matchId) => {
    const match = matches.find(match => match.matchId === matchId);
    if (match) {
      try {
        const response = await axios.post('https://assisai.com/matchesapi/api.php/prediction', {
          eventId,
          matchId,
          score1: match.predictedscore1,
          score2: match.predictedscore2
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        console.log('API Response:', data); // Agregar log para la respuesta de la API
        if (Array.isArray(data)) {
          const updatedMatches = matches.map(m => {
            const updatedMatch = data.find(d => d.matchId === m.matchId);
            return updatedMatch ? { ...m, ...updatedMatch, predicted: true } : m;
          });
          setMatches(updatedMatches);
          setMessage('Prediction saved successfully');
        } else if (data.message === 'Prediction updated successfully') {
          setMessage('Prediction updated successfully');
          const updatedMatches = matches.map(m => {
            if (m.matchId === matchId) {
              return { ...m, predictedscore1: match.predictedscore1, predictedscore2: match.predictedscore2, predicted: true };
            }
            return m;
          });
          setMatches(updatedMatches);
        } else {
          setMessage('Unexpected response format');
        }
        setModalIsOpen(true); // Mostrar el modal
      } catch (error) {
        console.error('Error saving prediction:', error); // Agregar log para errores
        setMessage('Failed to save prediction');
        setModalIsOpen(true); // Mostrar el modal en caso de error
      }
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('https://assisai.com/matchesapi/api.php/update-user', {
        email: newEmail,
        password: newPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      localStorage.setItem('token', response.data.token);
      setUpdateMessage('User updated successfully');
    } catch (error) {
      console.error('Error updating user:', error);
      setUpdateMessage('Failed to update user');
    }
  };

  const convertToLocalTime = (date, time) => {
    // Create a new Date object with the UTC time
    const utcDate = new Date(`${date}T${time}Z`);
    // Convert the UTC time to local time
    return utcDate.toLocaleString();
  };

  // Find the maximum points to set relative bar width
  const maxPoints = Math.max(...users.map(user => user.points), 0);

  const closeModal = () => {
    setModalIsOpen(false);
    setMessage('');
  };

  return (
    <div className="container">
      <h2>Dashboard</h2>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Prediction Status"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Notification</h2>
        <p>{message}</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
      <div className="users-list">
        <h3>Users and Points</h3>
        <ul>
          {users.map(user => (
            <li key={user.userId}>
              <span className="user-name">{user.userName}</span>
              <div className="points-bar-container">
                <div 
                  className="points-bar" 
                  style={{ width: `${(user.points / maxPoints) * 100}%`, backgroundColor: user.color }}
                >
                  {user.points} points
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="matches-list">
        <h3>Matches and Predictions</h3>
        <ul>
          {Array.isArray(matches) && matches.map(match => {
            // Convert match time from UTC to local time
            const matchLocalTime = new Date(`${match.date}T${match.time}Z`);
            const matchHasStarted = matchLocalTime <= new Date();
            return (
              <li key={match.matchId} className="match">
                <div className="match-details">
                  <img src={`/flags/${match.team1Code}.png`} alt={match.team1} />
                  <span>{match.team1}</span>
                  <span>vs</span>
                  <img src={`/flags/${match.team2Code}.png`} alt={match.team2} />
                  <span>{match.team2}</span>
                  <span className="date-time">{matchLocalTime.toLocaleString()}</span>
                  <span className="prediction-status">
                    {match.status === 'not started' ? 'Not started' :
                    match.status === 'in progress' ? 'In progress' :
                    `Score: ${match.score1}-${match.score2}`}
                  </span>
                  {match.predicted && <span className="points-earned">{match.points} points</span>}
                </div>
                <div className="prediction-inputs">
                  <input
                    type="number"
                    value={match.predictedscore1 || ''}
                    onChange={(e) => handlePredictionChange(match.matchId, e.target.value, match.predictedscore2)}
                    placeholder="Predicted Score 1"
                    min="0"
                    disabled={match.status !== 'not started'}
                  />
                  <input
                    type="number"
                    value={match.predictedscore2 || ''}
                    onChange={(e) => handlePredictionChange(match.matchId, match.predictedscore1, e.target.value)}
                    placeholder="Predicted Score 2"
                    min="0"
                    disabled={match.status !== 'not started'}
                  />
                  <button onClick={() => handleSavePrediction(match.matchId)} disabled={match.status !== 'not started'}>
                    {match.status === 'not started' ? (match.predicted ? 'Update Prediction' : 'Save Prediction') :
                     match.status === 'in progress' ? 'In Progress' :
                     'Finished'}
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="update-user">
        <h3>Update User</h3>
        <form onSubmit={handleUpdateUser}>
          <div>
            <label>New Email:</label>
            <input type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} required />
          </div>
          <div>
            <label>New Password:</label>
            <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
          </div>
          <button type="submit">Update</button>
        </form>
        {updateMessage && <p>{updateMessage}</p>}
      </div>
    </div>
  );
};

export default Dashboard;

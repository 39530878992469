import React, { useState } from 'react';
import axios from 'axios';

const UpdateUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const token = localStorage.getItem('token');

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('https://assisai.com/matchesapi/api.php/update-user', { email, password }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      localStorage.setItem('token', response.data.token);
      alert('User updated successfully');
    } catch (error) {
      alert('Update failed');
    }
  };

  return (
    <div>
      <h2>Update User</h2>
      <form onSubmit={handleUpdate}>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default UpdateUser;
